import { useMemo } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardIndex from './pages/DashboardIndex';
import NotFound from './pages/Page404';
import LogOut from './pages/LoginOut';
import Connect from './pages/Connect';
import SelectAccount from './pages/SelectAccount';
import DashboardDesktop from './pages/DashboardDesktop';
import DashboardMobileTablet from './pages/DashboardMobileTablet';

// ----------------------------------------------------------------------

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Router() {
  const query = useQuery();
  return useRoutes([
    {
      path: '/connect',
      element: <LogoOnlyLayout />,
      children: [{ path: '', element: <Connect /> }]
    },
    {
      path: '/select-account',
      element: <LogoOnlyLayout />,
      children: [{ path: '', element: <SelectAccount /> }]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <DashboardIndex /> },
        { path: 'desktop', element: <DashboardDesktop /> },
        { path: 'mobile-tablet', element: <DashboardMobileTablet /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to={`/dashboard/?${query}`} /> },
        { path: '*', element: <NotFound /> }
      ]
    },
    {
      path: '/logout',
      element: <LogOut />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
