import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { logScreenView } from '../utils/firebase/analytics';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

const gaView = '404';

// ----------------------------------------------------------------------

export default function Page404() {
  const [pageLoad, setPageLoad] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pageLoad) {
      setPageLoad(false);
      logScreenView(`${gaView}__${pathname}`);
    }
  }, [pageLoad, setPageLoad, pathname]);

  const handleHomeClick = () => {
    logScreenView(`${gaView}__home-click`);
    window.location = '/';
  };

  return (
    <RootStyle title="Page Not Found | BrowserFocus">
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Page Not Found
          </Typography>

          <Typography sx={{ mb: 5 }}>
            Sorry, we couldn't find the page you're looking for.
          </Typography>

          <Button size="large" onClick={handleHomeClick} variant="contained">
            Go Home
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
