import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Drawer, Typography, Stack } from '@mui/material';
// components
import { URL_EXTERNAL_BF_PURCHASE } from '../../config';
import { logEvent } from '../../utils/firebase/analytics';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from '../../config/sidebar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const gaView = 'dashboard_sidebar';
const gaEvents = {
  MENU_OPEN: `${gaView}__menu_open`,
  MENU_CLOSED: `${gaView}__menu_closed`,
  CLICK_UPGRADE: `${gaView}__click_upgrade`
};

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const handleUpgradeClick = () => {
    logEvent(gaEvents.CLICK_UPGRADE);
    window.open(URL_EXTERNAL_BF_PURCHASE, '_blank');
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ px: 2.5, pt: 10, pb: 3 }}>
        <Stack
          alignItems="center"
          spacing={1}
          sx={{
            p: 2.5,
            pt: 2,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Need more features?
            </Typography>
          </Box>

          <Button fullWidth onClick={handleUpgradeClick} variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
