import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from 'firebase/auth';
import {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID
} from '../../config';

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
};

export const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('profile');
googleProvider.addScope('email');

const loginErrors = {
  auth_popup_closed_by_user: {
    error: true,
    title: 'Login Cancelled',
    message: 'You closed the popup. You must login in the popup to continue.'
  },
  auth_generic: {
    error: true,
    title: 'Error',
    message: 'There has been an error, we are a looking into it.'
  }
};

const getErrorMessage = (errorCode) => {
  const errorKey = errorCode.replace(/\W+/g, '_');
  if (!Object.keys(loginErrors).includes(errorKey)) {
    console.error(`No signInWithGoogle error handling for key "${errorKey}", using generic error.`);
    return loginErrors.auth_generic;
  }
  return loginErrors[errorKey];
};

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    return { error: false, message: '' };
  } catch (err) {
    return getErrorMessage(err.code);
  }
};

const logOut = () => {
  try {
    signOut(auth);
  } catch (err) {
    console.error(err);
  }
  auth.signOut();
};

export { auth, signInWithGoogle, logOut };
