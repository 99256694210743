import PropTypes from 'prop-types';
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Button
} from '@mui/material';
// components
import { URL_EXTERNAL_BF_PURCHASE } from '../config';
import { logEvent } from '../utils/firebase/analytics';
import BrowserOsGroupedTableHead from './BrowserOsGroupedTableHead';

// ----------------------------------------------------------------------

BrowserScreenSizeGroupedTable.propTypes = {
  title: PropTypes.string,
  viewType: PropTypes.string
};

const columnLabels = [
  { id: 0, label: 'Browser (Version)' },
  { id: 1, label: 'OS' },
  { id: 2, label: 'Screen Size' },
  { id: 4, label: 'Users' },
  { id: 3, label: 'Support Level' }
];

export default function BrowserScreenSizeGroupedTable({ title, viewType }) {
  const handleUpgradeClick = () => {
    logEvent(`dashboard_${viewType}__click_upgrade`);
    window.open(URL_EXTERNAL_BF_PURCHASE, '_blank');
  };
  return (
    <>
      <Typography variant="h4" gutterBottom mb={2} mt={3}>
        {title}
      </Typography>
      <Card sx={{ overflowX: 'auto' }}>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <BrowserOsGroupedTableHead headCells={columnLabels} />
            <TableBody>
              <TableRow key="0" tabIndex={-1}>
                <TableCell colSpan="5" align="center" sx={{ pb: 3 }}>
                  <Typography variant="body2" gutterBottom mb={2} mt={1}>
                    View screen sizes &amp; customise support levels using Pro.
                  </Typography>
                  <Button onClick={handleUpgradeClick} variant="contained">
                    Upgrade to Pro
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
