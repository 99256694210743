import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import googleFill from '@iconify/icons-eva/google-fill';
import { useRecoilValue } from 'recoil';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton, Button } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import { logEvent } from '../../utils/firebase/analytics';
// state
import { userState } from '../../state/atoms/userState';
import { gaState } from '../../state/atoms/gaState';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  color: 'black',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const gaView = 'dashboard_nav';
const gaEvents = {
  CLICK_SELECT_ACCOUNT: `${gaView}__click_select_account`
};

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const user = useRecoilValue(userState);
  const ga = useRecoilValue(gaState);
  const navigateTo = useNavigate();

  const handleSelectAccount = () => {
    logEvent(gaEvents.CLICK_SELECT_ACCOUNT);
    navigateTo('/select-account');
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        {ga.authActive && (
          <>
            <Button onClick={handleSelectAccount} variant="contained">
              <Icon icon={googleFill} width={22} height={22} sx={{ display: 'none' }} />
              &nbsp;Select Account
            </Button>
          </>
        )}

        <Box sx={{ flexGrow: 1 }} />

        {user.isLoggedIn && (
          <AccountPopover
            email={user.email}
            displayName={user.displayName}
            photoURL={user.photoURL}
          />
        )}
      </ToolbarStyle>
    </RootStyle>
  );
}
