import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography, Button, CircularProgress } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import { getProfiles, getProperties } from '../utils/google/analytics';
import Page from '../components/Page';
import SelectField from '../components/SelectField';
import { logEvent, logScreenView } from '../utils/firebase/analytics';
// state
import { userState } from '../state/atoms/userState';
import { gaState } from '../state/atoms/gaState';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const gaView = 'select-account';
const gaEvents = {
  SELECT_ACCOUNT: `${gaView}__select-account`,
  SELECT_PROPERTY: `${gaView}__select-property`,
  SELECT_PROFILE: `${gaView}__select-profile`,
  CLICK_REVIEW_REPORT: `${gaView}__click-review-report`
};

// ----------------------------------------------------------------------

export default function SelectAccount() {
  const [pageLoad, setPageLoad] = useState(true);
  const [viewReport, setViewReport] = useState(false);
  const [loadingProperties, setLoadingProperties] = useState(false);
  const [selectProfile, setSelectProfile] = useState(false);
  const user = useRecoilValue(userState);
  const [ga, setGa] = useRecoilState(gaState);
  const navigateTo = useNavigate();

  const handleAccountChange = async (index) => {
    setLoadingProperties(true);
    setViewReport(false);
    setSelectProfile(false);
    const properties = await getProperties(ga.accounts[index].id);
    setGa({
      ...ga,
      selectedAccount: `${index}`,
      properties,
      selectedProperty: ''
    });
    logEvent(gaEvents.SELECT_ACCOUNT);
    setLoadingProperties(false);
  };

  const handlePropertyChange = async (index) => {
    const profiles = await getProfiles(ga.accounts[ga.selectedAccount].id, ga.properties[index].id);
    if (profiles.length > 1) {
      setGa({
        ...ga,
        selectedProperty: `${index}`,
        profiles
      });
      setSelectProfile(true);
      setViewReport(false);
      logEvent(gaEvents.SELECT_PROFILE);
    } else {
      setGa({
        ...ga,
        selectedProperty: `${index}`,
        profiles,
        selectedProfile: 0
      });
      setViewReport(true);
      logEvent(gaEvents.SELECT_PROPERTY);
    }
  };

  const handleProfileChange = async (index) => {
    setGa({
      ...ga,
      selectedProfile: `${index}`
    });
    setViewReport(true);
  };

  const handleViewReport = () => {
    logEvent(gaEvents.CLICK_REVIEW_REPORT);
    navigateTo('/');
  };

  useEffect(() => {
    if (!user) navigateTo('/login');

    if (!ga.authActive) navigateTo('/connect');

    if (pageLoad) {
      setPageLoad(false);
      logScreenView(gaView);
    }

    if (
      ga.selectedAccount !== '' &&
      ga.selectedProperty !== '' &&
      ga.profiles &&
      ga.selectedProfile !== ''
    ) {
      if (ga.profiles.length > 1) {
        setSelectProfile(true);
      }
      setViewReport(true);
    }
  }, [user, ga, navigateTo, pageLoad]);

  return (
    <RootStyle title="Connect | BrowserFocus">
      <AuthLayout />

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
              Select Account:
            </Typography>
            <SelectField
              label="Account"
              options={ga.accounts.map((account) => account.name)}
              selectedOption={ga.selectedAccount || ''}
              onUpdate={handleAccountChange}
            />
          </Stack>

          {loadingProperties && (
            <>
              <Stack sx={{ mb: 3 }}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center">
                  <Typography variant="h4" sx={{ mr: 2 }}>
                    Loading Properties
                  </Typography>
                  <CircularProgress color="primary" />
                </Stack>
              </Stack>
            </>
          )}
          {ga.properties && !loadingProperties && (
            <>
              <Stack sx={{ mb: 3 }}>
                <Typography variant="h4" gutterBottom>
                  Select Property:
                </Typography>
                <SelectField
                  label="Property"
                  options={ga.properties.map(
                    (property) => `${property.name}: ${property.websiteUrl}`
                  )}
                  selectedOption={ga.selectedProperty || ''}
                  onUpdate={handlePropertyChange}
                />
              </Stack>
            </>
          )}
          {ga.profiles && selectProfile && (
            <>
              <Stack sx={{ mb: 3 }}>
                <Typography variant="h4" gutterBottom>
                  Select Profile:
                </Typography>
                <SelectField
                  label="Profile"
                  options={ga.profiles.map((profile) => `${profile.name}`)}
                  selectedOption={ga.selectedProfile || ''}
                  onUpdate={handleProfileChange}
                />
              </Stack>
            </>
          )}
          {viewReport && (
            <Button fullWidth onClick={handleViewReport} variant="contained">
              View Report
            </Button>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
