import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useRecoilValue } from 'recoil';
// material
import { styled } from '@mui/material/styles';
// components
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { auth } from '../../utils/firebase/auth';
// state
import { gaState } from '../../state/atoms/gaState';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const ga = useRecoilValue(gaState);
  const navigateTo = useNavigate();

  useEffect(() => {
    let mounted = true;

    if (mounted && !loading) {
      if (!user) {
        navigateTo('/login');
      } else if (user && !ga.authActive) {
        navigateTo('/connect');
      }
      if (error) {
        console.error(error);
      }
    }
    return () => {
      mounted = false;
    };
  }, [user, loading, navigateTo, error, ga]);

  return (
    <RootStyle>
      {!loading && (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
          <MainStyle>
            <Outlet />
          </MainStyle>
        </>
      )}
    </RootStyle>
  );
}
