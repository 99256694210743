import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { Grid, Container, Stack, CircularProgress, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
// components
import Page from '../components/Page';
import { AppAccountDetails, AppTotalUsers, AppTotalBrowsers } from '../components/_dashboard/app';
import BrowserScreenSizeGroupedTable from '../components/BrowserScreenSizeGroupedTable';
import BrowserOsGroupedTable from '../components/BrowserOsGroupedTable';
import {
  getReport,
  groupRowsByBrowserOsVersion,
  getBrowserCountByDeviceType
} from '../utils/google/analytics';
import { fDate } from '../utils/formatTime';
import { logScreenView } from '../utils/firebase/analytics';
import DashboardFooter from '../components/DashboardFooter';
// state
import { gaState } from '../state/atoms/gaState';

// ----------------------------------------------------------------------
const browserByOsReportIds = ['browser', 'browserVersion', 'operatingSystem', 'users'];

const gaView = 'dashboard-mobile-tablet';

export default function DashboardMobileTablet() {
  const [pageLoad, setPageLoad] = useState(true);
  const [ga, setGa] = useRecoilState(gaState);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(false);
  const [rowsGroupedByBrowserOs, setRowsGroupedByBrowserOs] = useState(false);
  const [columnsByBrowserOs, setColumnsByBrowserOs] = useState(false);
  const navigateTo = useNavigate();

  useEffect(() => {
    if (pageLoad) {
      setPageLoad(false);
      logScreenView(gaView);
    }

    const loadReport = async () => {
      setLoading(true);
      const report = await getReport(ga.profiles[ga.selectedProfile].id);
      if (!report.error) {
        setRowsGroupedByBrowserOs(groupRowsByBrowserOsVersion(report.rows));
        const cols = report.columns.filter((column) => browserByOsReportIds.includes(column.id));
        cols.push({ id: 'supportLevel', label: 'Support Level', align: 'center' });
        setColumnsByBrowserOs(cols);
        // console.info(JSON.stringify(report, true, '\t'), '\n---------\n');
        setReport(report);
        setLoading(false);
      } else {
        setGa(false);
        navigateTo('/connect');
      }
    };

    if (ga.authActive && !report) {
      loadReport();
    }
  }, [ga, setGa, navigateTo, report, loading, pageLoad]);

  return (
    <Page title="Mobile and Tablet | BrowserFocus" sx={{ height: '100%' }}>
      {loading && (
        <Stack
          direction="column"
          sx={{ height: '100%' }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack direction="row" justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
            <Typography variant="h3" sx={{ ml: 2 }}>
              Loading
            </Typography>
          </Stack>
        </Stack>
      )}
      {!loading && (
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <AppAccountDetails
                account={ga.accounts[ga.selectedAccount].name}
                websiteUrl={ga.properties[ga.selectedProperty].websiteUrl.split('//')[1]}
                startDate={fDate(report.startDate)}
                endDate={fDate(report.endDate)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AppTotalUsers
                total={report.mobileUsers + report.tabletUsers}
                skipped={report.skippedUsers}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AppTotalBrowsers
                total={
                  getBrowserCountByDeviceType(report.browsers, 'mobile') +
                  getBrowserCountByDeviceType(report.browsers, 'tablet')
                }
              />
            </Grid>
            <Grid item xs={12}>
              <BrowserScreenSizeGroupedTable
                title="Mobile &amp; Tablet Browsers Screen Sizes"
                viewType="mobile_tablet"
              />
            </Grid>
            <Grid item xs={12}>
              <BrowserOsGroupedTable
                totalUsers={report.totalUsers}
                title="Mobile Browsers by OS"
                rows={rowsGroupedByBrowserOs.mobile}
                columnLabels={columnsByBrowserOs}
              />
            </Grid>
            <Grid item xs={12}>
              <BrowserOsGroupedTable
                totalUsers={report.totalUsers}
                title="Tablet Browsers by OS"
                rows={rowsGroupedByBrowserOs.tablet}
                columnLabels={columnsByBrowserOs}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardFooter view={gaView} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
