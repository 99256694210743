import { getAnalytics, logEvent as fbLogEvent } from 'firebase/analytics';
import { firebaseApp } from './auth';
import { APP_DEBUG_ENABLED } from '../../config';

const analytics = getAnalytics(firebaseApp);

export const labelToTrackingKey = (label) => label.replace(' ', '-').toLowerCase();

export const logEvent = (eventName) => {
  if (APP_DEBUG_ENABLED) console.info('logEvent', eventName);
  fbLogEvent(analytics, eventName);
};

export const logScreenView = (screenName, screenClass) => {
  if (APP_DEBUG_ENABLED) console.info('logScreenView', screenName, screenClass);
  fbLogEvent(analytics, 'screen_view', {
    firebase_screen: screenName,
    firebase_screen_class: screenClass
  });
};
