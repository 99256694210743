import { atom } from 'recoil';
import { localStorageEffect } from '../effects/localStorage';

const stateKey = 'userState';

export const userState = atom({
  key: stateKey,
  default: { isLoggedIn: false, uid: false, displayName: false, email: false, photoURL: false },
  effects_UNSTABLE: [localStorageEffect(stateKey)]
});
