import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import calendarFill from '@iconify/icons-eva/calendar-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Button, Card, Typography } from '@mui/material';
// utils

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.light
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

AppDateRange.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default function AppDateRange({ startDate, endDate }) {
  const handleUpdateClick = () => {};
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={calendarFill} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">
        <Button onClick={handleUpdateClick} variant="contained">
          Select range (Pro)
        </Button>
      </Typography>
      {/* <Typography variant="h3">Range</Typography> */}
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {startDate}&nbsp;&nbsp;&ndash;&nbsp;&nbsp;{endDate}
      </Typography>
    </RootStyle>
  );
}
