import PropTypes from 'prop-types';

import './google-signin-button.scss';

GoogleSigninButton.propTypes = {
  disabled: PropTypes.bool,
  onClicked: PropTypes.func
};

export default function GoogleSigninButton({ disabled, onClicked }) {
  const handleOnClick = () => {
    if (!disabled) onClicked();
  };

  const handleOnKeyPress = (event) => {
    console.info(event.code);
    if (event.code) handleOnClick();
  };

  return (
    <a
      onClick={handleOnClick}
      onKeyPress={handleOnKeyPress}
      role="button"
      tabIndex="0"
      className={`google-signin-button ${disabled && 'disabled'}`}
    >
      Sign in with Google
    </a>
  );
}
