import PropTypes from 'prop-types';
// material
import { Link, Stack, Typography } from '@mui/material';
import { URL_EXTERNAL_BF, URL_EXTERNAL_BF_TCS, URL_EXTERNAL_BF_PRIVACY } from '../config';
// components
import { logEvent } from '../utils/firebase/analytics';

const getYear = () => {
  const now = new Date();
  return now.getFullYear();
};

DashboardFooter.propTypes = {
  view: PropTypes.string
};

export default function DashboardFooter({ view }) {
  const handleClick = (link) => {
    logEvent(`${view}__footer-${link}`);
  };

  return (
    <Stack
      direction="column"
      sx={{ mt: 3, height: '100%' }}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body2">
        &copy; {getYear()}{' '}
        <Link href={URL_EXTERNAL_BF} onClick={() => handleClick('browser-focus')} target="_blank">
          BrowserFocus
        </Link>{' '}
        |{' '}
        <Link href={URL_EXTERNAL_BF_TCS} onClick={() => handleClick('tcs')} target="_blank">
          T&amp;Cs
        </Link>{' '}
        |{' '}
        <Link href={URL_EXTERNAL_BF_PRIVACY} onClick={() => handleClick('privacy')} target="_blank">
          Privacy Policy
        </Link>
      </Typography>
    </Stack>
  );
}
