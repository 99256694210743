import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import monitorFill from '@iconify/icons-eva/monitor-fill';
import smartphoneFill from '@iconify/icons-eva/smartphone-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'desktop',
    path: '/dashboard/desktop',
    icon: getIcon(monitorFill)
  },
  {
    title: 'mobile & tablet',
    path: '/dashboard/mobile-tablet',
    icon: getIcon(smartphoneFill)
  }
];

export default sidebarConfig;
