import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Button, Card, Stack, Typography } from '@mui/material';
// components

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  padding: theme.spacing(2, 2),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.light
}));

// ----------------------------------------------------------------------

AppAccountDetails.propTypes = {
  account: PropTypes.string,
  websiteUrl: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default function AppAccountDetails({ account, websiteUrl, startDate, endDate }) {
  const handleUpdateClick = () => {};
  return (
    <RootStyle>
      <Typography variant="h4" sx={{ opacity: 0.72 }} pb={1.5}>
        Report
      </Typography>
      <Stack direction="row" spacing={1} pb={0.25} justifyContent="space-between">
        <Typography variant="subtitle2">Account:</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }} noWrap>
          {account}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} pb={0.25} justifyContent="space-between">
        <Typography variant="subtitle2">Website:</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }} noWrap>
          {websiteUrl}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} pb={0.25} justifyContent="space-between">
        <Typography variant="subtitle2">Start Date:</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {startDate}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography variant="subtitle2">End Date:</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {endDate}
        </Typography>
      </Stack>
      <Typography variant="h3" pt={2} textAlign="center">
        <Button onClick={handleUpdateClick} variant="contained" disabled>
          Select Date range with Pro
        </Button>
      </Typography>
    </RootStyle>
  );
}
