import { Icon } from '@iconify/react';
import questionMarkFill from '@iconify/icons-eva/question-mark-circle-fill';
import feedbackFill from '@iconify/icons-eva/bulb-fill';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import { URL_EXTERNAL_BF_FAQS, URL_EXTERNAL_BF_FEEDBACK } from '../../config';
import MenuPopover from '../../components/MenuPopover';
import { logEvent, labelToTrackingKey } from '../../utils/firebase/analytics';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Submit Feedback',
    icon: feedbackFill,
    linkTo: URL_EXTERNAL_BF_FEEDBACK
  },
  {
    label: 'FAQs',
    icon: questionMarkFill,
    linkTo: URL_EXTERNAL_BF_FAQS
  }
];

AccountPopover.propTypes = {
  displayName: PropTypes.string.isRequired,
  photoURL: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

const gaView = 'dashboard_nav_account';
const gaEvents = {
  MENU_OPEN: `${gaView}__menu-open`,
  MENU_CLOSED: `${gaView}__menu-closed`,
  CLICK_EXTERNAL: `${gaView}__click_external`,
  CLICK_LOGOUT: `${gaView}__click_logout`
};

// ----------------------------------------------------------------------

export default function AccountPopover({ displayName, photoURL, email }) {
  const navigateTo = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    logEvent(gaEvents.MENU_OPEN);
    setOpen(true);
  };

  const handleClose = () => {
    logEvent(gaEvents.MENU_CLOSED);
    setOpen(false);
  };

  const handleLogout = () => {
    logEvent(gaEvents.CLICK_LOGOUT);
    navigateTo('/logout');
  };

  const handleExternalLink = (index) => {
    logEvent(`${gaEvents.CLICK_EXTERNAL}_${labelToTrackingKey(MENU_OPTIONS[index].label)}`);
    window.open(MENU_OPTIONS[index].linkTo, '_blank');
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={photoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option, index) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            onClick={() => handleExternalLink(index)}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
