import PropTypes from 'prop-types';
// material
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

SelectField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  selectedOption: PropTypes.string,
  onUpdate: PropTypes.func
};

// ----------------------------------------------------------------------

export default function SelectField({ label, options, selectedOption, onUpdate }) {
  const handleChange = (event) => {
    onUpdate(event.target.value);
  };
  const id = `select-${label.replace(' ', '-')}`;

  return (
    <FormControl>
      <InputLabel id={id}>{label}</InputLabel>
      <Select labelId={id} id={id} value={selectedOption} label={label} onChange={handleChange}>
        {options.map((option, index) => (
          <MenuItem key={index} value={index}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
