import PropTypes from 'prop-types';
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Chip
} from '@mui/material';
// components
import BrowserOsGroupedTableHead from './BrowserOsGroupedTableHead';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

BrowserOsGroupedTable.propTypes = {
  totalUsers: PropTypes.number,
  title: PropTypes.string,
  rows: PropTypes.array,
  columnLabels: PropTypes.array
};

const SUPPORT_FULL = 9;
const SUPPORT_LIMITED = 3;

const supportLevels = {
  1: { label: 'Full', colour: 'success' },
  2: { label: 'Limited', colour: 'warning' },
  3: { label: 'None', colour: 'error' }
};

const getSupportLevel = (users, totalUsers) => {
  const percentage = Math.round((users / totalUsers) * 100);
  let support;
  if (percentage >= SUPPORT_FULL) {
    support = 1;
  } else if (percentage >= SUPPORT_LIMITED && percentage < SUPPORT_FULL) {
    support = 2;
  } else {
    support = 3;
  }
  return { support, percentage };
};

const getVersionsList = (versions) => {
  let versionList = versions.sort().reverse().slice(0, 3).join(', ');
  if (versions.length > 3) versionList += ' ...';
  return versionList;
};

export default function BrowserOsGroupedTable({ totalUsers, title, rows, columnLabels }) {
  const sortedRows = applySortFilter(rows, getComparator('desc', 'users')).slice(0, 5);

  return (
    <>
      <Typography variant="h4" gutterBottom mb={2} mt={3}>
        {title}
      </Typography>
      <Card sx={{ overflowX: 'auto' }}>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <BrowserOsGroupedTableHead headCells={columnLabels} />
            <TableBody>
              {sortedRows.map((row, index) => {
                const supportLevel = getSupportLevel(row.users, totalUsers);
                return (
                  <TableRow hover key={index} tabIndex={-1}>
                    <TableCell align="left">{row.browser}</TableCell>
                    <TableCell align="left">{getVersionsList(row.browserVersions)}</TableCell>
                    <TableCell align="left">{row.operatingSystem}</TableCell>
                    <TableCell align="left">{row.users}</TableCell>

                    <TableCell align="left">
                      <Chip
                        label={`${supportLevels[supportLevel.support].label}
                        ${supportLevel.percentage}%`}
                        color={supportLevels[supportLevel.support].colour}
                        sx={{ width: '100%' }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}
