import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useRecoilState } from 'recoil';
// components
import { auth, logOut } from '../utils/firebase/auth';
// state
import { userState } from '../state/atoms/userState';

// ----------------------------------------------------------------------

export default function LogOut() {
  const [authUser, authLoading, authError] = useAuthState(auth);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  const navigateTo = useNavigate();
  useEffect(() => {
    if (authLoading) {
      // maybe trigger a loading screen
      return;
    }
    if (!authUser) {
      setUser({
        isLoggedIn: false,
        uid: null,
        displayName: null,
        email: null,
        photoURL: null
      });
      navigateTo('/login');
    }

    if (authError) {
      console.error(authError);
    }
  }, [authUser, authLoading, navigateTo, authError, setUser]);

  logOut();
  return false;
}
