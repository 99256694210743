import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography, Alert, AlertTitle, Checkbox, Link } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import { URL_EXTERNAL_BF_TCS, URL_EXTERNAL_BF_PRIVACY } from '../config';
import { auth, signInWithGoogle } from '../utils/firebase/auth';
import Page from '../components/Page';
import { logScreenView, logEvent } from '../utils/firebase/analytics';
import GoogleSigninButton from '../components/GoogleSigninButton/GoogleSigninButton';
// state
import { userState } from '../state/atoms/userState';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const gaView = 'login';
const gaEvents = {
  CONNECT_LOGIN: `${gaView}__click-login`,
  CONNECT_ACCEPT: `${gaView}__click-accept`,
  CONNECT_UNACCEPT: `${gaView}__click-unaccept`,
  CONNECT_LOGIN_ERROR: `${gaView}__login-error`
};
// ----------------------------------------------------------------------

export default function Login() {
  const [pageLoad, setPageLoad] = useState(true);
  const [accepted, setAccepted] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [authUser, authLoading, authError] = useAuthState(auth);
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useRecoilState(userState);
  const navigateTo = useNavigate();

  const handleLoginClick = async () => {
    setLoginError(false);
    setLoginLoading(true);
    logEvent(gaEvents.CONNECT_LOGIN);
    const signInResult = await signInWithGoogle();
    setLoginLoading(false);
    if (signInResult.error) {
      setLoginError(signInResult);
    }
  };

  const handleAcceptClick = () => {
    if (accepted) {
      logEvent(gaEvents.CONNECT_UNACCEPT);
    } else {
      logEvent(gaEvents.CONNECT_ACCEPT);
    }
    setAccepted(accepted !== true);
  };

  useEffect(() => {
    if (pageLoad) {
      setPageLoad(false);
      logScreenView(gaView);
    }

    if (authError) {
      // TODO: Handle authError
      console.error(JSON.stringify(authError, true, '\t'));
    }

    if (authUser) {
      setUser({
        isLoggedIn: true,
        uid: authUser.uid,
        displayName: authUser.displayName,
        email: authUser.email,
        photoURL: authUser.photoURL
      });
      navigateTo('/connect');
    }

    if (authError) {
      console.error(authError);
    }
  }, [setUser, authUser, authLoading, authError, navigateTo, pageLoad]);
  return (
    <RootStyle title="Log In | BrowserFocus">
      <AuthLayout />

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 3 }}>
            {loginError && (
              <Alert sx={{ mb: 3 }} variant="outlined" severity="error">
                <AlertTitle>{loginError.title}</AlertTitle>
                {loginError.message}
              </Alert>
            )}
            <Typography variant="h3">Log Into BrowserFocus</Typography>
            <Typography variant="p">
              Sign in with your Google account that has access to Google Analytics below:
            </Typography>
          </Stack>
          {loginLoading && <GoogleSigninButton disabled />}
          {!loginLoading && (
            <GoogleSigninButton disabled={!accepted} onClicked={handleLoginClick} />
          )}
          <Stack direction="row" sx={{ mt: 1 }} alignItems="center">
            <Checkbox
              value="accepted"
              onClick={handleAcceptClick}
              sx={{ paddingLeft: 0 }}
              checked={accepted}
            />
            <Typography variant="body2">
              I accept the{' '}
              <Link href={URL_EXTERNAL_BF_TCS} target="_blank">
                T&amp;Cs
              </Link>{' '}
              and{' '}
              <Link href={URL_EXTERNAL_BF_PRIVACY} target="_blank">
                Privacy Policy
              </Link>
              .
            </Typography>
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
