import daysjs from 'dayjs';

const config = {
  // DEV
  localhost: {
    // APP
    app_base_url: 'http://localhost:3000',
    // GOOGLE
    google_client_id: '77154127435-j2i5okd93182j8q0n6tb7m7p1c18oddp.apps.googleusercontent.com',
    // FIREBASE
    firebase_api_key: 'AIzaSyAN2eowQdJX4oO6_gzyEZLWZLSOIKv4eZE',
    firebase_auth_domain: 'browserfocusapp-dev.firebaseapp.com',
    firebase_project_id: 'browserfocusapp-dev',
    firebase_storage_bucket: 'browserfocusapp-dev.appspot.com',
    firebase_messaging_sender_id: '999436743558',
    firebase_app_id: '1:999436743558:web:16fa24ccc19f754e19f207',
    firebase_measurement_id: 'G-XXMLH5TBE9'
  },
  // PROD
  'app.browserfocus.io': {
    // APP
    app_base_url: 'https://app.browserfocus.io:3000',
    // GOOGLE
    google_client_id: '170891236376-16e4ivjpbavhhlpj368davpp5d99k8pj.apps.googleusercontent.com',
    // FIREBASE
    firebase_api_key: 'AIzaSyDeBUfHQTh4sCKWqijluaOlWCAyzfYNkQQ',
    firebase_auth_domain: 'browserfocusapp-prod.firebaseapp.com',
    firebase_project_id: 'browserfocusapp-prod',
    firebase_storage_bucket: 'browserfocusapp-prod.appspot.com',
    firebase_messaging_sender_id: '1005636377117',
    firebase_app_id: '1:1005636377117:web:9794a1d374bd6a3984c4f4',
    firebase_measurement_id: 'G-BZK0QYGCX3'
  }
};

// hack for local IP
config['192.168.1.64'] = config.localhost;

// APP
export const APP_BASE_URL = config[window.location.hostname]?.app_base_url;
export const APP_REPORT_DEFAULT_START_DATE = daysjs().subtract(12, 'months').format('YYYY-MM-DD');
export const APP_REPORT_DEFAULT_END_DATE = daysjs().format('YYYY-MM-DD');
export const APP_DEBUG_ENABLED = process.env.NODE_ENV !== 'production';

// GOOGLE
export const GOOGLE_CLIENT_ID = config[window.location.hostname]?.google_client_id;
export const GOOGLE_SCOPES = ['https://www.googleapis.com/auth/analytics.readonly'];

// FIREBASE
export const FIREBASE_API_KEY = config[window.location.hostname]?.firebase_api_key;
export const FIREBASE_AUTH_DOMAIN = config[window.location.hostname]?.firebase_auth_domain;
export const FIREBASE_PROJECT_ID = config[window.location.hostname]?.firebase_project_id;
export const FIREBASE_STORAGE_BUCKET = config[window.location.hostname]?.firebase_storage_bucket;
export const FIREBASE_MESSAGING_SENDER_ID =
  config[window.location.hostname]?.firebase_messaging_sender_id;
export const FIREBASE_APP_ID = config[window.location.hostname]?.firebase_app_id;
export const FIREBASE_MEASUREMENT_ID = config[window.location.hostname]?.firebase_measurement_id;

// External URLs
export const URL_EXTERNAL_BF = 'https://browserfocus.io';
export const URL_EXTERNAL_BF_FAQS = 'https://browserfocus.io/faq/';
export const URL_EXTERNAL_BF_FEEDBACK = 'https://browserfocus.io/feedback/';
export const URL_EXTERNAL_BF_TCS = 'https://browserfocus.io/terms-conditions/';
export const URL_EXTERNAL_BF_PRIVACY = 'https://browserfocus.io/privacy-policy/';
export const URL_EXTERNAL_BF_PURCHASE = 'https://browserfocus.io/purchase/';
