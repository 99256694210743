import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
// material
import { styled } from '@mui/material/styles';
import { Container, Link, Typography, Alert, AlertTitle } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import { URL_EXTERNAL_BF_TCS, URL_EXTERNAL_BF_PRIVACY } from '../config';
import GoogleSigninButton from '../components/GoogleSigninButton/GoogleSigninButton';
import Page from '../components/Page';
import { getAccountsList } from '../utils/google/analytics';
import { userState } from '../state/atoms/userState';
import { gaState } from '../state/atoms/gaState';
import { logScreenView, logEvent } from '../utils/firebase/analytics';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const gaView = 'connect';
const gaEvents = {
  CONNECT_TO_GA: `${gaView}__click-connect`
};

// ----------------------------------------------------------------------

export default function Connect() {
  const [pageLoad, setPageLoad] = useState(true);
  const [connecting, setConnecting] = useState(false);
  const [authError, setAuthError] = useState(false);
  const user = useRecoilValue(userState);
  const [ga, setGa] = useRecoilState(gaState);
  const navigateTo = useNavigate();

  const handleGetAccountList = async () => {
    setAuthError(false);
    setConnecting(true);
    logEvent(gaEvents.CONNECT_TO_GA);
    const accountsListResponse = await getAccountsList();
    if (accountsListResponse.error) {
      setConnecting(false);
      setAuthError(accountsListResponse);
    } else {
      setGa({ authActive: true, accounts: accountsListResponse.data });
      navigateTo('/select-account');
    }
  };

  useEffect(() => {
    if (pageLoad) {
      setPageLoad(false);
      logScreenView(gaView);
    }

    if (!user) navigateTo('/login');
    // if (ga.authActive && ga.selectedAccount === '') navigateTo('/select-account');
    // GA is authenticated & account selected - start using the app!
    // if (ga.authActive && ga.selectedAccount !== '') navigateTo('/');
  }, [user, ga, navigateTo, pageLoad]);

  return (
    <RootStyle title="Connect | BrowserFocus">
      <AuthLayout />

      <Container maxWidth="sm">
        <ContentStyle>
          {authError && (
            <Alert sx={{ mb: 3 }} variant="outlined" severity="error">
              <AlertTitle>{authError.title}</AlertTitle>
              {authError.message}
            </Alert>
          )}
          <Typography variant="h3">Connect To Google Analytics</Typography>
          <Typography variant="p" sx={{ mb: 3 }}>
            Sign in below to allow BrowserFocus to download* your Google Analytics data (in the
            popup).
          </Typography>
          {connecting && <GoogleSigninButton disabled />}
          {!connecting && <GoogleSigninButton disabled={false} onClicked={handleGetAccountList} />}
          <Typography variant="body2" sx={{ mt: 2 }}>
            *BrowserFocus does not retain any data downloaded from your Google Analytics accounts.
            Your data is only used during your session to generate your browser support reports.
          </Typography>
          <br />
          <Typography variant="body2" align="center">
            <Link href={URL_EXTERNAL_BF_TCS} target="_blank">
              T&amp;Cs
            </Link>{' '}
            |{' '}
            <Link href={URL_EXTERNAL_BF_PRIVACY} target="_blank">
              Privacy Policy
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
