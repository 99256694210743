import { useEffect, useMemo, useState } from 'react';
// material
import { Grid, Container, Stack, CircularProgress, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
// components
import Page from '../components/Page';
import {
  AppAccountDetails,
  AppTotalUsers,
  AppTotalBrowsers,
  AppDeviceShare
} from '../components/_dashboard/app';
import BrowserOsGroupedTable from '../components/BrowserOsGroupedTable';
import { getReport, groupRowsByBrowserOsVersion } from '../utils/google/analytics';
import { fDate } from '../utils/formatTime';
import { logScreenView } from '../utils/firebase/analytics';
// state
import { gaState } from '../state/atoms/gaState';
// components
import DashboardFooter from '../components/DashboardFooter';

// ----------------------------------------------------------------------
const browserByOsReportIds = ['browser', 'browserVersion', 'operatingSystem', 'users'];

const gaView = 'dashboard-index';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function DashboardApp() {
  const [pageLoad, setPageLoad] = useState(true);
  const [ga, setGa] = useRecoilState(gaState);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(false);
  const [rowsGroupedByBrowserOs, setRowsGroupedByBrowserOs] = useState(false);
  const [columnsByBrowserOs, setColumnsByBrowserOs] = useState(false);
  const navigateTo = useNavigate();
  const query = useQuery();

  useEffect(() => {
    if (pageLoad) {
      setPageLoad(false);
      if (query.get('plan')) {
        logScreenView(`${gaView}--plan-${query.get('plan')}`);
      } else {
        logScreenView(gaView);
      }
    }

    const loadReport = async () => {
      setLoading(true);
      const report = await getReport(ga.profiles[ga.selectedProfile].id);
      if (!report.error) {
        setRowsGroupedByBrowserOs(groupRowsByBrowserOsVersion(report.rows));
        const cols = report.columns.filter((column) => browserByOsReportIds.includes(column.id));
        cols.push({ id: 'supportLevel', label: 'Support Level', align: 'center' });
        setColumnsByBrowserOs(cols);
        // console.info(JSON.stringify(report, true, '\t'), '\n---------\n');
        setReport(report);
        setLoading(false);
      } else {
        setGa(false);
        navigateTo('/connect');
      }
    };

    if (ga.authActive && !report) {
      loadReport();
    }
  }, [ga, setGa, navigateTo, query, report, loading, pageLoad]);

  return (
    <Page title="Dashboard | BrowserFocus" sx={{ height: '100%' }}>
      {loading && (
        <Stack
          direction="column"
          sx={{ height: '100%' }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack direction="row" justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
            <Typography variant="h3" sx={{ ml: 2 }}>
              Loading
            </Typography>
          </Stack>
        </Stack>
      )}
      {!loading && (
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <AppAccountDetails
                account={ga.accounts[ga.selectedAccount].name}
                websiteUrl={ga.properties[ga.selectedProperty].websiteUrl.split('//')[1]}
                startDate={fDate(report.startDate)}
                endDate={fDate(report.endDate)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppTotalUsers total={report.totalUsers} skipped={report.skippedUsers} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <AppTotalBrowsers total={Object.keys(report.browsers).length} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AppDeviceShare
                mobile={report.mobileUsers}
                tablet={report.tabletUsers}
                desktop={report.desktopUsers}
              />
            </Grid>
            <Grid item xs={12}>
              <BrowserOsGroupedTable
                totalUsers={report.totalUsers}
                title={`Desktop ${report.desktopUsersPercentage}%`}
                rows={rowsGroupedByBrowserOs.desktop}
                columnLabels={columnsByBrowserOs}
              />
            </Grid>
            <Grid item xs={12}>
              <BrowserOsGroupedTable
                totalUsers={report.totalUsers}
                title={`Mobile ${report.mobileUsersPercentage}%`}
                rows={rowsGroupedByBrowserOs.mobile}
                columnLabels={columnsByBrowserOs}
              />
            </Grid>
            <Grid item xs={12}>
              <BrowserOsGroupedTable
                totalUsers={report.totalUsers}
                title={`Tablet ${report.tabletUsersPercentage}%`}
                rows={rowsGroupedByBrowserOs.tablet}
                columnLabels={columnsByBrowserOs}
              />
            </Grid>
            <Grid item xs={12}>
              <DashboardFooter view={gaView} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
