import { atom } from 'recoil';

const stateKey = 'gaState';

export const gaState = atom({
  key: stateKey,
  default: {
    authActive: false,
    accounts: [],
    selectedAccount: '',
    properties: [],
    selectedProperty: '',
    profiles: [],
    selectedProfile: ''
  }
});
