import {
  APP_REPORT_DEFAULT_START_DATE,
  APP_REPORT_DEFAULT_END_DATE,
  GOOGLE_CLIENT_ID,
  GOOGLE_SCOPES
} from '../../config';

/* global gapi */

const DEVICE_MOBILE = 'mobile';
const DEVICE_DESKTOP = 'desktop';
const DEVICE_TABLET = 'tablet';

// dimensions > Column names
const columnNameMap = {
  browser: 'Browser',
  browserSize: 'Browser Size',
  browserVersion: 'Browser Version',
  operatingSystem: 'OS',
  operatingSystemVersion: 'OS Version',
  screenResolution: 'Screen Resolution',
  deviceCategory: 'Device Category',
  users: 'Users'
};

const authErrors = {
  popup_closed_by_user: {
    error: true,
    title: 'You Closed the Popup',
    message: 'You must grant access to your Google Analytics account in the popup to continue.'
  },
  access_denied: {
    error: true,
    title: 'You Denied Access',
    message:
      'You denied access in the popup. You must grant access to your Google Analytics account in the popup to continue.'
  },
  auth_generic: {
    error: true,
    title: 'Error',
    message: 'There has been an error, we are a looking into it.'
  },
  403: {
    error: true,
    title: 'Error',
    message: 'User is not authorised.'
  }
};

const getErrorMessage = (errorCode) => {
  const errorKey = errorCode.replace('-', '_');
  if (!Object.keys(authErrors).includes(errorKey)) {
    console.error(`No analytics access error handling for key "${errorKey}", using generic error.`);
    return authErrors.auth_generic;
  }
  return authErrors[errorKey];
};

const createAccountsList = (response) =>
  response.result.items.map((account) => ({ id: account.id, name: account.name }));

const createPropertiesList = (response) =>
  response.result.items.map((property) => ({
    id: property.id,
    accountId: property.accountId,
    name: property.name,
    websiteUrl: property.websiteUrl,
    permissions: property.permissions.effective
  }));

const createProfilesList = (response) =>
  response.result.items.map((profile) => ({
    id: profile.id,
    accountId: profile.accountId,
    propertyId: profile.webPropertyId,
    name: profile.name,
    websiteUrl: profile.websiteUrl,
    permissions: profile.permissions.effective
  }));

// ie: Chrome_Windows_10
const createBrowserOsDimensionsKey = (dimensions) =>
  `${dimensions.browser.replace(' ', '')}_${dimensions.operatingSystem}_${
    dimensions.operatingSystemVersion
  }`;

// const groupReportByScreenSize;

const getColumnHeaders = (rawColumnHeaders) =>
  rawColumnHeaders.map((col) => ({
    id: col.name.split(':')[1],
    label: columnNameMap[col.name.split(':')[1]]
  }));

const generateReportRow = (rowList, columns) => {
  const row = {};

  columns.forEach((column, index) => {
    row[column.id] = rowList[index];
  });

  return row;
};

const generateReport = (rawResponse) => {
  const report = {};
  // Calculate totalUsers when skipping (not set) dimensions
  report.totalUsers = 0;
  report.skippedUsers = 0;
  report.mobileUsers = 0;
  report.mobileUsersPercentage = 0;
  report.desktopUsers = 0;
  report.desktopUsersPercentage = 0;
  report.tabletUsers = 0;
  report.tabletUsersPercentage = 0;
  report.totalRecords = 0;
  report.skippedRecords = 0;
  report.startDate = rawResponse.result.query['start-date'];
  report.endDate = rawResponse.result.query['end-date'];
  report.browsers = {};
  report.unknownOs = [];
  report.profileInfo = rawResponse.result.profileInfo;
  report.columns = getColumnHeaders(rawResponse.result.columnHeaders);
  report.rows = [];

  rawResponse.result.rows.forEach((rowList) => {
    const row = generateReportRow(rowList, report.columns);
    // skip any records containing (not set)
    if (!rowList.includes('(not set)')) {
      row.users = parseInt(row.users, 10);
      report.totalUsers += row.users;
      // const deviceType = osToDeviceTypeMap[row.operatingSystem] || DEVICE_UNKNOWN;
      if (row.deviceCategory === DEVICE_MOBILE) {
        report.mobileUsers += row.users;
      } else if (row.deviceCategory === DEVICE_DESKTOP) {
        report.desktopUsers += row.users;
      } else if (row.deviceCategory === DEVICE_TABLET) {
        report.tabletUsers += row.users;
      } else {
        // TODO: Capture & report these for mapping
        report.unknownOs.push(row);
      }
      // row.deviceType = deviceType;
      if (!Object.prototype.hasOwnProperty.call(report.browsers, row.browser)) {
        report.browsers[row.browser] = {
          users: row.users,
          deviceType: [row.deviceCategory]
        };
      } else {
        report.browsers[row.browser].users += row.users;
        if (!report.browsers[row.browser].deviceType.includes(row.deviceCategory)) {
          report.browsers[row.browser].deviceType.push(row.deviceCategory);
        }
      }
      report.rows.push(row);
    } else {
      report.skippedUsers += parseInt(row.users, 10);
      report.skippedRecords += 1;
    }
  });
  report.mobileUsersPercentage = Math.round((report.mobileUsers / report.totalUsers) * 100);
  report.desktopUsersPercentage = Math.round((report.desktopUsers / report.totalUsers) * 100);
  report.tabletUsersPercentage = Math.round((report.tabletUsers / report.totalUsers) * 100);
  report.totalRecords = report.rows.length;
  // console.info(JSON.stringify(report, true, '\t'), '\n---------\n');
  return report;
};

export const authorise = async () => {
  const authData = {
    client_id: GOOGLE_CLIENT_ID,
    scope: GOOGLE_SCOPES
  };

  try {
    await gapi.auth.authorize(authData);
    return {
      error: false
    };
  } catch (err) {
    return getErrorMessage(err.error);
  }
};

export const getAccountsList = async () => {
  const authorised = await authorise();

  if (authorised.error) return authorised;

  let accountList = [];

  try {
    await gapi.client.load('analytics', 'v3');
    accountList = await gapi.client.analytics.management.accounts.list();
    // console.info(JSON.stringify(accountList, true, '\t'));
    return {
      error: false,
      data: createAccountsList(accountList)
    };
  } catch (errorResponse) {
    // console.error(JSON.stringify(errorResponse, true, '\t'));
    return getErrorMessage(`${errorResponse.result.error.code}`);
  }
};

export const getProperties = async (accountId) => {
  let accountProperties;
  try {
    accountProperties = await gapi.client.analytics.management.webproperties.list({ accountId });
    // console.info(JSON.stringify(accountProperties, true, '\t'));
    return createPropertiesList(accountProperties);
  } catch (errorResponse) {
    // console.error(JSON.stringify(errorResponse, true, '\t'));
    return getErrorMessage(`${errorResponse.result.error.code}`);
  }
};

export const getProfiles = async (accountId, propertyId) => {
  let profiles;
  try {
    profiles = await gapi.client.analytics.management.profiles.list({
      accountId,
      webPropertyId: propertyId
    });
    return createProfilesList(profiles);
    // console.info(JSON.stringify(profiles, true, '\t'));
  } catch (errorResponse) {
    // console.error(JSON.stringify(errorResponse, true, '\t'));
    return getErrorMessage(`${errorResponse.result.error.code}`);
  }
};

export const getReport = async (profileId, startDate, endDate, metrics, dimensions) => {
  const reportStartDate = startDate || APP_REPORT_DEFAULT_START_DATE;
  const reportEndDate = endDate || APP_REPORT_DEFAULT_END_DATE;
  const reportMetricsArray = metrics || ['users'];
  const reportDimensionsArray = dimensions || [
    'browser',
    // 'browserSize',
    'browserVersion',
    'operatingSystem',
    'operatingSystemVersion',
    'screenResolution',
    'deviceCategory'
  ];
  let reportResponse;

  const reportMetrics = reportMetricsArray.map((metric) => `ga:${metric}`);

  const reportDimensions = reportDimensionsArray.map((dimension) => `ga:${dimension}`);
  try {
    reportResponse = await gapi.client.analytics.data.ga.get({
      ids: `ga:${profileId}`,
      'start-date': reportStartDate,
      'end-date': reportEndDate,
      'max-results': 10000,
      metrics: reportMetrics.join(','),
      dimensions: reportDimensions.join(',')
    });
    // console.info(JSON.stringify(reportResponse, true, '\t'));
    return generateReport(reportResponse);
  } catch (errorResponse) {
    // console.error(JSON.stringify(errorResponse, true, '\t'));
    return getErrorMessage(`${errorResponse.result.error.code}`);
  }
};

export const groupRowsByBrowserOsVersion = (reportRows) => {
  const rows = { mobile: [], tablet: [], desktop: [] };
  const rowsMap = { mobile: {}, tablet: {}, desktop: {} };
  reportRows.forEach((reportRow) => {
    const key = createBrowserOsDimensionsKey(reportRow);
    const browserVersion = reportRow.browserVersion.split('.')[0];
    const deviceCategory = reportRow?.deviceCategory;
    const os = `${reportRow.operatingSystem} ${reportRow.operatingSystemVersion}`;
    if (!Object.prototype.hasOwnProperty.call(rowsMap[deviceCategory], key)) {
      rowsMap[deviceCategory][key] = rows[deviceCategory].length;
      rows[deviceCategory].push({
        browser: reportRow.browser,
        browserVersions: [browserVersion],
        operatingSystem: os,
        users: reportRow.users
      });
    } else {
      rows[deviceCategory][rowsMap[deviceCategory][key]].users += reportRow.users;
      if (
        !rows[deviceCategory][rowsMap[deviceCategory][key]].browserVersions.includes(browserVersion)
      ) {
        rows[deviceCategory][rowsMap[deviceCategory][key]].browserVersions.push(browserVersion);
      }
    }
  });
  // console.info(JSON.stringify(rows, true, '\t'));
  return rows;
};

export const getBrowserCountByDeviceType = (browsers, deviceType) =>
  Object.values(browsers).filter((browser) => browser.deviceType.includes(deviceType)).length;
